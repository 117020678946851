import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function postTemplate({ data }) {
  if (!data) return null

  const {
    id,
    title,
    content,
  } = data.allWpPost.nodes[0]

  return (
    <Layout>
      <Seo title={title} />
      <div className="container">
        <h1>Titre:{title}, ID: {id}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getPost($id: String!) {
    allWpPost(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        content
        slug
      }
    }
  }
`
